<template>
<div class="calendarCard">
  <div class="calendar-list">
    <ul>
        <li
          v-for="k in knowledges"
          :key="k.id"
          :class="listClass(k.id)"
          @click="showKnowledgeData(k)"
        >
          <BookingListAll :list="k" />
        </li>
        <li v-if="knowledges.length === 0">No {{bookingType}} booking found.</li>
      </ul>
  </div>
</div>
      <!-- <div class="KnowledgeDetails-wrap">
        <div class="row">
          <div class="col-xxl-12 col-xl-12 col-md-12">
            <div class="KnowledgeDetails-card">
              <div class="know-center-list">
                <ul>
                  <li
                    v-for="k in knowledges"
                    :key="k.id"
                    :class="listClass(k.id)"
                    @click="showKnowledgeData(k)"
                  >
                    <BookingListAll :list="k" />
                  </li>
                  <li v-if="knowledges.length === 0">No {{bookingType}} booking found.</li>
                </ul>
              </div>
            </div>
          </div>
         
        </div>
      </div> -->
</template>
<script>
/*eslint-disable*/
import axios from "axios";
import BookingListAll from "@/views/CalendarBooking/partials/BookingListAll";
import { mapGetters } from "vuex";

export default {
  name: "BookingList",
  components: {
    BookingListAll,
  },
  props: {
    bookingType: {
      required: true,
    },
  },
   computed: {
    ...mapGetters({ rep: "rep" }),
  },
  data: () => ({
    knowledges: [],
    showKnowledge: null,
    currentItemId: null,
  }),
  methods: {
    listClass(id) {
      let activeClass = this.currentItemId == id ? "active" : "";
      return `knowledge-list ${activeClass}`;
    },
    showKnowledgeData(row) {
      this.showKnowledge = row;
      this.currentItemId = row.id
    },
    getBookings() {
      let url = this.$endpoint.CALENDAR_API_URL
      url+= 'booking-new/'+this.bookingType+'?username='+this.rep.code;
      console.log(url);
      let loader = this.$loading.show();
      const otherUrl = axios.create({
                baseURL: "",
                });
      otherUrl
        .get(url)
        .then((res) => {
          this.knowledges = res.data.data;
          this.showKnowledge = this.knowledges[0];
          this.currentItemId = this.showKnowledge.id
        })
        .catch(() => {
          this.knowledges = [];
          this.showKnowledge = null;
          this.currentItemId = null
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.getBookings();
  },
};
</script>
<style src="@/assets/css/knowledge.css"></style>