<template>
  <a>
    <div class="dashknw-icon">
      <!--<img :src="image" alt="" /> -->
       <span style="display:block" class="msg-date"><b>{{startTime }} </b></span>
       <span class="msg-date">{{timeDuration }}</span>
    </div>
    <div class="dashknw-content">
      <h3><b>{{list.title}}</b> <span class="accepted-class" v-if="!list.confirmed">Unconfirmed</span></h3>
      <span class="msg-date">{{ list.description}}</span><br/>
      <span class="msg-date">{{ list.attendees.length ? list.attendees[0].email : '' }}</span>
    </div>
  </a>
</template>
<script>
import dummyImage from "@/assets/images/user.svg";
import moment from "moment";
export default {
  name: "BookingListAll",
  props:{
      list:{
          required:true
      }
  },
    data: () => ({
    image: dummyImage,
  }),
  computed: {
    startTime() {
      return moment(this.list.startTime).format("DD MMMM YYYY");
    },
     timeDuration() {
      return moment(this.list.startTime).format("H:mm") + ' - ' + moment(this.list.endTime).format("H:mm")
    },
  },
};
</script>
<style scoped>
.dashknw-icon {
  width: 100%;
  font-size: 14px;
}
.msg-date {
  font-size: 13px;
  
}
.accepted-class {
  background:#f2e59a;
  color:#590909;
  padding: 2px;
  font-size: 12px;

}
.dashknw-icon {
  width: 18%;
  display: inline-block
}
.dashknw-content {
  width: 80%;
  display: inline-block
}
.dashknw-content .msg-date {
  
}
</style>