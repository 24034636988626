var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', [_c('div', {
    staticClass: "dashknw-icon"
  }, [_c('span', {
    staticClass: "msg-date",
    staticStyle: {
      "display": "block"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.startTime) + " ")])]), _c('span', {
    staticClass: "msg-date"
  }, [_vm._v(_vm._s(_vm.timeDuration))])]), _c('div', {
    staticClass: "dashknw-content"
  }, [_c('h3', [_c('b', [_vm._v(_vm._s(_vm.list.title))]), _vm._v(" "), !_vm.list.confirmed ? _c('span', {
    staticClass: "accepted-class"
  }, [_vm._v("Unconfirmed")]) : _vm._e()]), _c('span', {
    staticClass: "msg-date"
  }, [_vm._v(_vm._s(_vm.list.description))]), _c('br'), _c('span', {
    staticClass: "msg-date"
  }, [_vm._v(_vm._s(_vm.list.attendees.length ? _vm.list.attendees[0].email : ''))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }