<template>
  <div class="page-wrapper know-center-wrap knowledge-center-wrap">
    <div class="container-fluid">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h1>Calendar Booking</h1>
      </div>
      <b-card no-body>
        <b-tabs card v-model="tabIndex">
          <b-tab title="Upcoming" @click="tabIndex = 0">
            <b-card-text>
                <BookingList bookingType="upcoming" />
            </b-card-text>
          </b-tab>

            <b-tab title="Past" @click="tabIndex = 1">
            <b-card-text>
               <BookingList bookingType="past" />
            </b-card-text>
          </b-tab>

         <b-tab title="Cancelled" @click="tabIndex = 2">
            <b-card-text>
               <BookingList bookingType="cancelled" />
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>
<script>

import BookingList from './BookingList.vue';
export default {
  name: "CalendarBooking",
  components: {
    BookingList,
    
  },
  data: () => ({
    knowledges: [],
    showKnowledge: null,
    currentItemId: null,
    breadcrumbItems: [{ title: "Calendar Booking", active: true }],
    tabIndex: 0,
  }),

};
</script>
<style src="@/assets/css/knowledge.css"></style>
<style src="@/assets/css/calendar.css"></style>
